import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from 'components/dashboard'
import Users from 'components/users'
import UserDetails from 'components/users/Details'
import Analysis from 'components/analysis'
import Cdr from 'components/cdr'
import Release from 'components/release'
import PushNotifications from 'components/pushNotifications'
import Campaigns from 'components/campaigns'
import Switches from 'components/switches'
import InspectLog from 'components/logs/inspectLog'
import InspectResult from 'components/logs/inspectResult'
import Widgets from 'components/widgets'
import Partners from 'components/partners'
import PartnerDetails from 'components/partners/details'
import Insights from 'components/insights'
import { CalcTest } from 'components/calctest'
import { OpenBill } from 'components/insights/openBill'
import Segments from 'components/segment'

const Routes = () => (
    <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/inspectLog" exact component={InspectLog} />
        <Route path="/inspectResult" exact component={InspectResult} />
        <Route path="/users" exact component={Users} />
        <Route path="/partners" exact component={Partners} />
        <Route path="/partners/:PartnerID" exact component={PartnerDetails} />   
        <Route path="/users/:user_id" component={UserDetails} />
        <Route path="/insights" component={Insights} />
        <Route path="/analysis" component={Analysis} />
        <Route path="/cdr" component={Cdr} />
        <Route path="/releases" component={Release} />
        <Route path="/push" component={PushNotifications} />
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/segments" component={Segments} />
        <Route path="/switches" component={Switches} />
        <Route path="/widgets" component={Widgets} />
        <Route path="/calctest" component={CalcTest} />
        <Route path="/openbill/:BillID" component={OpenBill} />
    </Switch>
)

export default Routes
