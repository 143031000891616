import gql from 'graphql-tag'

export default gql`
    query getSegments {
        getSegments {
            ID
            Type
            Description
            Notes {
                AuthorUserID
                AuthorEmail
                Content
                Time
            }
            Audience {
                PartnerID
                LastCalc
                Audience{
                    UserID
                    UserEmail
                    DateJoined
                }
            }
            PriorAudience {
                PartnerID
                LastCalc
                Audience {
                    UserID
                    UserEmail
                    DateJoined
                    DateLeft
                }
            }
            LastUpdated
            Name
        }
    }
`
