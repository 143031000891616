import { useMutation } from "@apollo/client"
import { Button, Input, Modal, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import manualCompare from "graphql/mutations/manualCompare"
import { ExtractBillData, Insight } from "graphql/types"
import React from "react"
import { VerifyResult } from "./verifyResult"

interface Props {
    extract: ExtractBillData
}

export const ExtractView = ({ extract }: Props) => {
    const [verifyResult, setVerifyResult] = React.useState<Insight|null>(null)
    const [extractData, setExtractData] = React.useState<ExtractBillData|null>(null)
    const [verify, result] = useMutation(manualCompare)
    const verifyThisInsight = () => {
        verify({
            variables: {
                ExtractBillData: JSON.stringify(extractData)
            }, 
            onCompleted: (data) => {
                setVerifyResult(data.adminManualCompare)
            }
        })
    }
    React.useEffect(() => {
        if (result.error   ) {
            alert(result.error)
        }
    }, [result])
    React.useEffect(() => {
        setExtractData(extract)
    }, [])
    if (!extractData) {
        return <div>Loading</div>
    }
    return (
        <div style={{ border: '1px solid #eee', padding: '10px' }}>
            <Table>         
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Key</TableCell>
                        <TableCell style={{fontWeight: "bold"}}>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.entries(extract).map(([key, v]) => {
                            if (typeof v === 'object') {
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        
                                        <TableCell>
                                            <Input value={JSON.stringify(extractData[key])} onChange={(e) => {
                                                extractData[key] = e.target.value
                                                setExtractData({...extractData })
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            return (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    
                                    <TableCell>
                                        <Input 
                                            value={extractData[key]} 
                                            onChange={(e) => {
                                                if (typeof extractData[key] === 'number') 
                                                    extractData[key] = parseFloat(e.target.value)
                                                else 
                                                    extractData[key] = e.target.value
                                                setExtractData({...extractData })
                                            }
                                        } />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <div style={{ border: '1px solid #eee', padding: '10px', background: '#f9f9f9' }}>
                { result.loading ? <div style={{textAlign:"right"}}>Running verification...</div> : (
                    <div style={{textAlign:"right"}}>
                        <Button onClick={() => verifyThisInsight()} style={{background: "lightgreen", margin: "2px"}}>Verify this Insight</Button>
                        <Button onClick={() => {}} style={{background: "lightgreen", margin: "2px"}}>Regenerate Insight for User</Button>
                    </div>
                )}
            </div>
            <Modal open={verifyResult !== null} onClose={() => setVerifyResult(null)}>
                <VerifyResult insight={verifyResult} />
            </Modal>
        </div>
    )
}