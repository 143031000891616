import { useQuery } from "@apollo/client"
import { MenuItem, Select } from "@material-ui/core"
import partners from "graphql/queries/partners"
import { Partner } from "graphql/types"
import React from "react"

export const PartnerSelector = ({partnerID, setPartnerID}) => {
    const { loading, error, data } = useQuery(partners)
    if (error) return <div>failure {console.error(error)} </div>
    if (loading) return <div>Loading</div>
    const p = data?.partners
    return (
        <div style={{position:'absolute', top: '50px', right: '570px', padding: '5px 10px', zIndex: 1000 }}>
            <Select value={partnerID} onChange={(e) => setPartnerID(e.target.value as string)} style={{fontSize: "22px", padding: "5px 2px"}} >
                {p.map((p: Partner) => (
                    <MenuItem style={{fontSize: "22px"}} key={p.ID} value={p.ID} >{p.Name}</MenuItem>
                ))}
                <MenuItem style={{fontSize: "22px"}} key="superadmin" value="superadmin" >superadmin</MenuItem>
            </Select>
        </div>
    )
}