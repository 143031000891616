import gql from 'graphql-tag'

export default gql`
    query getCampaignEvents($CampaignID: String!) {
        getCampaignEvents(CampaignID: $CampaignID) {
            DateCreated
			DateUpdated
			CampaignID
			ID
			Type
			Status
			AudienceTotal
			PushedUserIDs	
			PushTotal
			IsDraft
        }
    }
`