import { Box, Button } from "@material-ui/core"
import { Insight } from "graphql/types"
import React from "react"

interface Props {
    insight: Insight
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}


export const VerifyResult = ({insight} :Props) => {
    const env = process.env.ENVIRONMENT == "prod" ? "prod" : "dev"
    return (
        <Box sx={style}>
            <div style={{"marginBottom": "8px", "borderBottom": "1px solid grey"}}>Verify Result Summary</div>
            { insight.Error ? <div>{insight.Error}</div> : (
                <>
                    <div>Insight verification complete</div>
                    <Button
                        style={{border: "1px solid #333", padding: "5px 15px", margin: "5px 0"}}
                        href={`https://demo.web.${env}.acaciamoney.com/${insight.Domain}/openinsight/${insight.UserID}/${insight.InsightID}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        VIEW INSIGHT
                    </Button>
                    <div><strong>Provider: </strong>{insight.Summary?.ProviderName}</div>
                    <div><strong>Status: </strong>{insight.Summary?.Status}</div>
                    <div><strong>Max Dollar: </strong>{insight.Summary?.MaxDollar}</div>
                    <div><strong>Max Leaf: </strong>{insight.Summary?.MaxLeaf}</div>
                </>
            )}
        </Box>
    )
    
}