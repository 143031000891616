import { useQuery } from '@apollo/client'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import { Button, Dialog } from '@material-ui/core'
import getSegments from 'graphql/queries/getSegments'
import { Segment } from 'graphql/types'
import { EditSegment } from './edit'
import SegmentDetails from './deets'
import { PartnerSelector } from 'components/partners/selector'

const Segments = () => {
    const { loading, error, data } = useQuery(getSegments, { fetchPolicy: 'no-cache' })
    const [ editSegment, setEditSegment ] = React.useState<Segment | null>(null)
    const [ partnerID, setPartnerID ] = React.useState<string | null>('racq')
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.getSegments) data.getSegments = []
    
    const createNewSegment = () => {
        setEditSegment({
            ID: '', 
            Name: '',
            Type: 'dynamic',
            Description: '',
            LastUpdated: '',
        })
    }

    
    const thisData = data.getSegments.map((a: Segment) => {
        const audienceCount = a.Audience?.find(a => a.PartnerID === partnerID)?.Audience.length || 0
        return {
            ID: a.ID,
            Description: a.Description,
            Name: a.Name,
            Type: a.Type,
            CurrentAudience: audienceCount,
            Audience: a.Audience,
            PriorAudience: a.PriorAudience,
        }
    })
    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Segments
                </h1>
                <PartnerSelector partnerID={partnerID} setPartnerID={setPartnerID} />
                <div>
                    <Button onClick={() => createNewSegment()}>Create new Segment</Button>
                </div>
            </div>
            <Dialog open={!!editSegment} onClose={() => setEditSegment(null)}>
                {editSegment && <EditSegment Segment={editSegment} setEditSegment={setEditSegment} />}
            </Dialog>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'ID', field: 'ID'},
                        { title: 'Name', field: 'Name' },
                        { title: 'Description', field: 'Description' },
                        { title: 'Status', field: 'Status' },
                        { title: 'Current Audience', field: 'CurrentAudience' },
                    ]}
                    data={thisData}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                    // onRowClick={(event, rowData: any) => {
                    //     window.open(`/users/${rowData.UserID}`, '_blank')
                    // }}
                    detailPanel={(rowData) => {
                        return (
                            <div>
                                <SegmentDetails Segment={rowData as unknown as Segment} setEditSegment={setEditSegment} partnerID={partnerID} />
                            </div>
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default Segments
