import { Button, Modal, Table } from '@material-ui/core'
import * as gql from '../../../graphql/types'
import React from "react"

interface Props {
    Campaign: gql.Campaign
}

export const Clicks = ({Campaign}: Props) => {
    const [clicksModal, setClicksModal] = React.useState<boolean>(false)
    return (
        <>
            <div 
                onClick={() => setClicksModal(true)}
                style={{ cursor: "pointer", textAlign: 'center', border: '1px solid grey', borderRadius: '5px', padding: '10px 15px', fontSize: '24px', fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: 'lightgrey', width: "150px" }}
            >
                <div>Clicks</div>
                <div>
                    {Campaign.Clicks.length}
                </div>
            </div>
            <Modal open={clicksModal} onClose={() => setClicksModal(false)}>
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', height: '80%', backgroundColor: 'white', padding: '20px', overflow: 'scroll'}}>
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => setClicksModal(false)}>Close</Button>
                    </div>
                    <Table style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Link</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Campaign.Clicks.map((click, i) => (
                                <tr key={i}>
                                    <td>{click.Date}</td>
                                    <td>{click.EmailAddress}</td>
                                    <td>{click.Link}</td>
                                    <td>{click.Tags}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </>
    )
}