import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as BiIcons from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import partners from 'graphql/queries/partners'
import partnerRunSync from 'graphql/mutations/partnerRunSync'
import { Button } from '@material-ui/core'
import adminUserAttribute from 'graphql/mutations/adminUserAttribute'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { Alert } from '@material-ui/lab'
import { set } from 'date-fns'
import { Partner } from 'graphql/types'

const PartnerDetails = () => {
    const { PartnerID } = useParams<any>()
    const [userAlert, setUserAlert] = React.useState<string|null>(null)
    const q = useQuery(partners)
    const { token } =
        useAuth0()
    const [runSheet, mutation] = useMutation(partnerRunSync, {
        refetchQueries: [{ query: partners }], 
        onError: (e) => {
            alert(e)
        }, 
        onCompleted: (e) => {
            setUserAlert("Sheet run successfully")
            setTimeout(() => {
                setUserAlert(null)
            }, 5000)
        }
    })
    const [makeMe, mutaich] = useMutation(adminUserAttribute, {
        refetchQueries: [{ query: partners }], 
        onCompleted: (e) => {
            setUserAlert("You are now testing this partner")
            setTimeout(() => {
                setUserAlert(null)
            }, 5000)
        }
    })
    if (q.loading) return <></>
    const partner: Partner = q.data.partners.find(a => a.ID == PartnerID)
    if (!partner) throw new Error("partner not found: "+ PartnerID)
    return (
        <div>
            
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
            >
                {partner.Name}
            </h1>
            {userAlert && (
                <Alert severity="success">
                    {userAlert}
                </Alert>
            )}
            <div>
                {partner.Logo && <div>
                    <img src={partner.Logo} />
                </div> }
            </div>
            <div style={{gridTemplateColumns: "1fr 1fr", display: "grid"}}>
                <div>
                    <div>
                        <label>Name: </label>
                        <input type="text" value={partner.Name} />
                    </div>
                    <div>
                        <label>Last Sync: </label>
                        <input type="text" value={partner.LastSync} />
                    </div>
                    { mutation.loading ? <div>Running sheet...</div>  : (
                        <div>
                            <label>Sheet: </label>
                            <input type="text" value={partner.Sheet} />
                            { partner.Sheet && partner.Sheet != "" &&
                                <div>
                                    <div>
                                        <Button onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${partner.Sheet}/edit#gid=0`)}>
                                            Go to sheet
                                        </Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => runSheet({variables: {
                                            GroupID: partner.ID
                                        }})}>
                                            Run sheet
                                        </Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => makeMe({variables: {
                                            UserID: token["sub"], 
                                            AttributeKey: "partnerID",
                                            AttributeValue: partner.ID,
                                        }})}>
                                            Make this my test partner
                                        </Button>
                                    </div>
                                </div>
                                
                            }

                        </div>
                    )}
                </div>
                <div>
                    <strong>Toggles</strong>
                    <div style={{border: "1px solid #ccc", padding: "5px"}}>
                        {partner.ModuleConfig && partner.ModuleConfig.map(a => (
                            <div key={a.Key} style={{display: "flex", marginBottom: "5px"}}>
                                <div style={{width: "300px", border: "1px solid #ddd", padding: "5px", marginRight: "10px"}}>{a.Key}</div>
                                <input  style={{width: "50px", border: "1px solid #ccc", padding: "5px", textAlign: "center"}} type="text" value={a.Value} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerDetails
