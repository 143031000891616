import gql from 'graphql-tag'

export default gql`
    mutation adminManualCompare($ExtractBillData: String!) {
        adminManualCompare(ExtractBillData: $ExtractBillData) {
            GeneratedAt
            InsightID
            UserID
            Current
            Domain
            Status
            TTL
            LogGroup
            LogStream
            ExecID
            Error
            BillId
            Summary {
                MaxDollar
                MaxLeaf
                Source
                Status
                ProviderName
            }
        }
    }
`
