import {  useMutation, } from '@apollo/client'
import {  Tabs, Tab, Box, Button } from '@material-ui/core'
import * as React from 'react'
import * as styles from '../styles'
import * as gql from '../../../graphql/types'
import { TabPanelProps } from '@material-ui/lab'
import MaterialTable from 'material-table'
import { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import mutateSegment from 'graphql/mutations/segment'
type Props = {
    Segment: gql.Segment
    setEditSegment: (Segment: gql.Segment | null) => void
    partnerID: string
}


const SegmentDetails = ({Segment, setEditSegment, partnerID}: Props) => {
    const [recalcSegment, t] = useMutation(mutateSegment, { 
        variables: {
            SegmentID: Segment.ID
        },
        onError: (error) => {
            alert("Something has gone wrong: " + error.message)
        }, 
        onCompleted: () => {
            alert("DONE")
        }
    })
    const [ showAudience, setShowAudience ] = React.useState<string>("current")

    const audience = showAudience === "current" ?
        (Segment.Audience?.find(a => a.PartnerID === partnerID)?.Audience || []) :
        (Segment.PriorAudience?.find(a => a.PartnerID === partnerID)?.Audience || [])
    const au = audience.map((a: gql.AudienceMember) => {
        return {
            Email: a.UserEmail,
            DateJoined: a.DateJoined,
            DateLeft: a.DateLeft
        }
    })
    console.log(Segment, "Segment")
    const recalc = () =>  {
        recalcSegment({
            variables: {
                ID: partnerID,
                Data: Segment.ID, 
                Action: "recalc"

            }
        })
    }

    function setEditAudience(): void {
        throw new Error('Function not implemented.')
    }

    return (
        <div style={{margin: "13px 8px"}}>
            <div>

                <div className={styles.row} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button 
                        size='large'
                        variant="contained"
                        color="primary"
                        onClick={() => setEditSegment(Segment)}
                    >Edit</Button>

                    { Segment.Type === 'dynamic' ?
                        <Button
                            size='large'
                            variant="contained"
                            color="primary"
                            onClick={() => recalc()}
                        >Recalculate</Button>
                        : <Button
                            size='large'
                            variant="contained"
                            color="primary"
                            onClick={() => setEditAudience()}
                        >Edit Audience</Button>
                    }
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={() => setEditSegment(Segment)}
                    >Export Analysis</Button>
                    <Button 
                        variant="outlined"
                        color="default"
                        onClick={() => setShowAudience(showAudience === "current" ? "prior" : "current")}
                    >Showing: {showAudience === "current" ? "Current" : "Prior" } Audience</Button>
                    <Button 
                        variant="contained"
                        color="secondary"
                        onClick={() => setEditSegment(Segment)}
                    >Delete</Button>
                </div>
                <div className={styles.row}>
                    <MaterialTable
                        icons={tableIcons as Icons}
                        title={'Events'}
                        columns={[
                            { title: 'Email', field: 'Email' },
                            { title: 'Date Joined', field: 'DateJoined' },
                            { title: 'Date Left', field: 'DateLeft' },
                        ]}
                        // onRowClick={(event, rowData, togglePanel) => {

                        // }
                        data={au}
                        options={{
                            toolbar: false,
                            showTitle: true,
                            sorting: true,
                            filtering: true,
                            search: false,
                            pageSize: 50,
                            paging: false
                        }}

                    />
            </div>
        </div>
    </div>
    )
}

export default SegmentDetails
