import { useQuery } from '@apollo/client'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import getCampaigns from 'graphql/queries/getCampaigns'

import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'

import { Insight, Partner } from 'graphql/types'
import adminInsights from 'graphql/queries/adminInsights'
import InsightDetails from './details'
import { Box, FormControl, InputLabel, MenuItem, Select, Switch } from '@material-ui/core'
import partners from 'graphql/queries/partners'

const Insights = () => {
    const [alertStatus, setAlertStatus] = React.useState(true)
    const [partnerID, setPartnerID] = React.useState('racq')
    const { loading: partnersLoading, error: err, data: partnerData } = useQuery(partners)
    const { loading, error, data, refetch } = useQuery(adminInsights, { 
        fetchPolicy: 'no-cache', 
        variables: { PartnerID: partnerID }
    })

    const [domain, setDomain] = React.useState('energy')
    if (loading || partnersLoading) return <div>Loading</div>
    if (error || err) return <div>failure {console.error(error || err)} </div>
    
    const foundUsers = new Map<string, Insight[]>()
    if (!data) return <div>No data</div>
    data.adminInsights
        .filter(a => a.Domain === domain)
        .filter(a => alertStatus ? a.Status === "Escalate" || a.Status === "Error" || (a.Status === "" && a.Error !== "") : true)
        .forEach((insight: Insight) => {
            if (!foundUsers.has(insight.UserID)) {
                foundUsers.set(insight.UserID, [])
            }
            foundUsers.get(insight.UserID)?.push(insight)
        })

    const p = partnerData?.partners

    const arr = Array.from(foundUsers, ([name, value]) => {
        const hasError = value.filter((v) => v.Error !== "")
        const uniqueErrors = new Set(hasError.filter(a => a.Error !== "").
            map((v) => v.Error.substring(39))).size
        const uniqueBills = new Set(value.map((v) => v.BillHash)).size
        const firstUploadDate = new Date(value.sort((a, b) => new Date(a.GeneratedAt).getTime() - new Date(b.GeneratedAt).getTime())[0].GeneratedAt).toLocaleString()
        const lastUploadDate = new Date(value.sort((a, b) => new Date(b.GeneratedAt).getTime() - new Date(a.GeneratedAt).getTime())[0].GeneratedAt).toLocaleString()
        const badStatus = Boolean(value.filter((v) => v.Status === "Escalate" || v.Status === "Error" || (v.Status === "" && v.Error !== "")).length)
        const current = value[0].Error !== "" ? "Error" : (value[0].Status === "" || value[0].Status === "Complete") ? "OK" : value[0].Status
        const provider = value.length > 0 && value[0].Summary?.ProviderName ? value[0].Summary.ProviderName : "N/A"
        return {
            errorUploads: hasError.length,
            name: name,
            email: value[0].UserEmail,
            totalUploads: value.length,
            uniqueErrors,
            uniqueBills,
            provider,
            lastUploadDate, 
            firstUploadDate,
            insights: value, 
            badStatus, 
            current
        }
    })

    const tableData = arr
    return (
        <div style={{position:'relative'}}>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Energy Insight Issues
                </h1>
            </div>
            <div style={{position:'absolute', top: '50px', right: '570px', padding: '5px 10px', zIndex: 1000, flexFlow: 'flex-wrap', display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="partner-id">PartnerID</InputLabel>
                        <Select label="PartnerID" labelId="partner-id" value={partnerID} onChange={(e) => setPartnerID(e.target.value as string)} style={{fontSize: "22px", padding: "5px 2px", }} >
                            {p.map((p: Partner) => (
                                <MenuItem style={{fontSize: "22px"}} key={p.ID} value={p.ID} >{p.Name}</MenuItem>
                                    
                            ))}
                            <MenuItem style={{fontSize: "22px"}} key={p.ID} value="superadmin" >superadmin</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="domain">Domain</InputLabel>
                        <Select label="PartnerID" labelId="Domain" value={domain} onChange={(e) => setDomain(e.target.value as string)} style={{fontSize: "22px", padding: "5px 2px", }} >
                            <MenuItem style={{fontSize: "22px"}} key={"energy"} value={"energy"}>energy</MenuItem>
                            <MenuItem style={{fontSize: "22px"}} key={"solar"} value={"solar"}>solar</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 120, marginLeft: '10px', display: 'flex', alignItems: 'center', border: '1px solid #333', padding: '5px 10px', borderRadius: '5px' }}>
                    <div>SHOW ALL</div>
                    <Switch checked={alertStatus} onChange={(e) => setAlertStatus(!alertStatus)} />
                    <div>OPS ALERT</div>
                </Box>
            </div>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'UserID', field: 'name', render: (rowData) => <a href={`/users/${rowData.name}`}>{rowData.name}</a> },
                        { title: 'Email', field: 'email'},
                        { title: 'Provider', field: 'provider'},
                        // { title: 'First Upload Date', field: 'firstUploadDate'},
                        { title: 'Last Upload Date', field: 'lastUploadDate'},
                        // { title: 'Total Uploads', field: 'totalUploads'},
                        // { title: 'Failed Uploads', field: 'errorUploads'},
                        // { title: 'Unique Errors', field: 'uniqueErrors'},
                        // { title: 'Unique Bills', field: 'uniqueBills'},
                        { title: 'User Status', field: 'current'},
                        //{ title: 'Alert Status', field: 'badStatus', defaultFilter: ["true"], lookup: { true: 'true', false: 'false' },},
                        
                    ]}
                    data={tableData}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                    detailPanel={(rowData) => {
                        return <InsightDetails InsightDetails={rowData as any} refetch={refetch} />
                    }}
                />
            </div>
        </div>
    )
}

export default Insights
