import React from 'react'
import { EditValues } from './values'
import { Button, MenuItem, Select, TextField, Dialog, DialogContent } from '@material-ui/core'
import * as styles from '../styles'
import usersOverTime from 'graphql/queries/usersOverTime'
import adminUsers from 'graphql/queries/adminUsers'
import { useQuery } from '@apollo/client'

interface Props {
    userIDs: string[]
    processCampaign: () => void
    testCampaign: () => void

}

export const DraftDetails = ({userIDs, processCampaign, testCampaign}: Props) => {

    const result = useQuery(adminUsers, {
        variables: {
            PartnerID: "racq"
        }
    })
    if (!userIDs) return <div></div>
    if (result.loading) return <div>Loading</div>
    if (result.error) return <div>failure {console.error(result.error)} </div>
    if (!result.data.adminUsers) return <div>Loading</div>
    const users = result.data.adminUsers
    return (
        <DialogContent>
            <div className={styles.buttons}>
                <button className={styles.buttonsRun} onClick={() => processCampaign()}>Run</button>
                <button className={styles.buttonsTest} onClick={() => testCampaign()}>Test</button>
            </div>
            <div>Recipients:</div>
            { userIDs.length === 0 ? "NO EMAILS WILL BE SENT!" : (userIDs as any).map(a => (
                <div>
                    <Button className={styles.buttonsUser} key={a} onClick={() => window.open("/users/"+a)}>
                        {users.find((u: any) => u.user_id === a)?.email || a}
                    </Button>
                </div>
            )) }
        </DialogContent>
    )
}