import { useQuery } from '@apollo/client'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import getCampaigns from 'graphql/queries/getCampaigns'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import CampaignDetails from './deets'
import { Campaign } from 'graphql/types'
import { Button, Dialog } from '@material-ui/core'
import { EditCampaign } from './edit'

const Campaigns = () => {
    const { loading, error, data, refetch } = useQuery(getCampaigns, { fetchPolicy: 'no-cache' })
    const [ editCampaign, setEditCampaign ] = React.useState<Campaign | null>(null)

    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.getCampaigns) data.getCampaigns = []
    const refetchCampaigns = () => refetch()

    const createNewCampaign = () => {
        setEditCampaign({
            ID: '',
            Priority: '',
            Name: '',
            Channel: '',
            Notes: '',
            DateCreated: new Date().toISOString(),
            DateCompleted: null,
            DateDrafted: null,
            TotalPushes: "0",
            Message: '',
            Title: '',
            Description: '',
            Status: 'Draft',
            Logic: '',
            Doc: '',
            Values: '',
        })
    }

    const thisData = data.getCampaigns.map((a: Campaign) => {
        return {
            ID: a.ID,
            DateCreated: new Date(a.DateCreated).toLocaleString(),
            DateCompleted: a.DateCompleted,
            DateDrafted: a.DateDrafted,
            TotalPushes: a.TotalPushes,
            Message: a.Message,
            Title: a.Title,
            Description: a.Description,
            Status: a.Status,
            Logic: a.Logic,
            Doc: a.Doc,
            Values: a.Values,
            Name: a.Name,
            Priority: Number(a.Priority),
            Clicks: a.Clicks,
        }
    })
    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Campaigns
                </h1>
                <div>
                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1qL3Hq6yo6JdMFEMOC-EE3NKIssr1jb9IN1_41fY6uvQ/edit?gid=0#gid=0" rel="noreferrer">Open master sheet</a>
                </div>
                <div>
                    <Button onClick={() => createNewCampaign()}>Create new campaign</Button>
                </div>
            </div>
            <Dialog open={!!editCampaign} onClose={() => setEditCampaign(null)}>
                {editCampaign && <EditCampaign Campaign={editCampaign} setEditCampaign={setEditCampaign} />}
            </Dialog>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'ID', field: 'ID'},
                        { title: 'Name', field: 'Name' },
                        { title: 'Priority', field: 'Priority',  },
                        { title: 'Description', field: 'Description' },
                        { title: 'Status', field: 'Status' },
                    ]}
                    data={thisData}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                    // onRowClick={(event, rowData: any) => {
                    //     window.open(`/users/${rowData.UserID}`, '_blank')
                    // }}
                    detailPanel={(rowData) => {
                        return <CampaignDetails Campaign={rowData as Campaign} refetchCampaigns={refetchCampaigns} setEditCampaign={setEditCampaign} />
                    }}
                />
            </div>
        </div>
    )
}

export default Campaigns
