
import { useMutation } from "@apollo/client";
import { Button, Input, MenuItem, Select, TextareaAutosize } from "@material-ui/core";
import segment from "graphql/mutations/segment";
import { Segment } from "graphql/types";
import * as React from 'react';

interface Props {
  Segment: Segment | undefined;
  setEditSegment: (Segment: Segment | null) => void;
}

export const EditSegment = ({Segment, setEditSegment}: Props) => {

    const [mutateSegment, r] = useMutation(segment, {
        refetchQueries: ['getSegments'],
        onError: (error) => {
            alert("Something has gone wrong: " + error.message)
        }, 
        onCompleted: () => {
            setEditSegment(null)
        }
    })
    const [newID, setNewID] = React.useState(Segment ? Segment.ID : "");
    const [description, setDescription] = React.useState(Segment ? Segment.Description : "");
    const [name, setName] = React.useState(Segment ? Segment.Name : "");
    const [type, setType] = React.useState(Segment ? Segment.Type : "");
    
    return (
        <div style={{padding:"12px"}}>
            <div>
                <label style={{marginRight: "17px"}}>ID</label>
                <span>
                    { Segment && Segment.ID != "" ? Segment.ID : (
                        <Input value={newID}  onChange={(e) => setNewID(e.currentTarget.value)} />
                    )}
                </span>
            </div>
            <div>
                <label>Name</label>
                <div>
                    <Input value={name} onChange={(e) => setName(e.currentTarget.value)}/>
                </div>
            </div>

            
            <div>
                <label>Type</label>
                <div>
                    <Select value={type} onChange={(e) => setType(e.target.value as string)}>
                        <MenuItem value="dynamic">Dynamic</MenuItem>
                        <MenuItem value="static">Static</MenuItem>
                    </Select>
                </div>
            </div>
            <div>
                <label>Description</label>
                <div>
                    <TextareaAutosize style={{border: "1px solid #ddd", width: "100%", height: "300px"}} value={description} onChange={(e) => setDescription(e.currentTarget.value)}/>
                </div>
            </div>
            { r.loading ? 
                <div>Loading...</div> : 
                <Button onClick={async () => {
                    const data = {
                        ID: Segment && Segment.ID ? Segment.ID : newID,
                        Description: description,
                        Name: name,
                        Type: type,
                    }

                    await mutateSegment({
                        variables: {
                            ID: Segment && Segment.ID ? Segment.ID : newID,
                            Action: "edit", 
                            Data: JSON.stringify(data),
                        }
                    })
                }}>Save</Button>   
            }
        </div>
    )
}
           