import gql from 'graphql-tag'

export default gql`
    query getCampaigns {
        getCampaigns {
            ID
            Name
            Priority
            DateCreated
            DateCompleted
            DateDrafted
            Notes
            Message
            Title
            Description
            Status
            Logic
            Values
            Doc
            TotalClicks
            Clicks {
                ID
                CampaignID
                PartnerID
                UserID
                Link
                Date
                EmailAddress
                Tags
                TestUser
            }
        }
    }
`
